import $ from "jquery";

let isPerMonth = true;

const vCoreRangeValues = [
  0.5, 1, 2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 40, 48,
];

const linuxMonthly = 0;
const linuxHourly = 0;
const windowsMonthly = 600;
const windowsHourly = 820;

const ramCostPerUnitMonthly = 125;
const procesadorCostPerUnitMonthly = 250;
const ssdCostPerUnitMonthly = 10;

const magnitudeForMonthly = 100;

const ramCostPerUnitHourly = 171;
const procesadorCostPerUnitHourly = 342;
const ssdCostPerUnitHourly = 14;

const magnitudeForHourly = 100000;

let licenciaValue = "linux";
let ramValue = 2;
let vCoreValue = 0.5;
let ssdValue = 5;

function updateCalculation() {
  $(".home-section2__ram-text").text(`${ramValue * vCoreValue} GB`);

  const vCoreValueText = vCoreValue === 0.5 ? "1/2" : vCoreValue;
  $(".home-section2__vcore-text").text(`${vCoreValueText} Cores`);

  $(".home-section2__ssd-text").text(`${ssdValue} GB`);

  // Update Price Table

  if (isPerMonth) {
    const licenciaCostPerUnit =
      licenciaValue === "linux" ? linuxMonthly : windowsMonthly;
    const totalLicenciaCost = licenciaCostPerUnit * vCoreValue;
    const licenciaCostPerUnitWithMagnitude =
      licenciaCostPerUnit / magnitudeForMonthly;
    const totalLicenciaCostWithMagnitude =
      totalLicenciaCost / magnitudeForMonthly;
    const licenciaCostPerUnitDisplayText =
      licenciaValue === "linux"
        ? licenciaCostPerUnitWithMagnitude
        : licenciaCostPerUnitWithMagnitude.toFixed(2);
    const totalLicenciaCostPerUnitDisplayText =
      licenciaValue === "linux"
        ? totalLicenciaCostWithMagnitude
        : totalLicenciaCostWithMagnitude.toFixed(2);
    $(".home-section2__group2-price-licensia .column1").text(
      `${licenciaCostPerUnitDisplayText}€`,
    );
    $(".home-section2__group2-price-licensia .column2").text(
      `${totalLicenciaCostPerUnitDisplayText}€`,
    );

    const totalRamCost = ramCostPerUnitMonthly * ramValue * vCoreValue;
    $(".home-section2__group2-price-ram .column1").text(
      `${(ramCostPerUnitMonthly / magnitudeForMonthly).toFixed(2)}€`,
    );
    $(".home-section2__group2-price-ram .column2").text(
      `${(totalRamCost / magnitudeForMonthly).toFixed(2)}€`,
    );

    const totalProcesadorCost = procesadorCostPerUnitMonthly * vCoreValue;
    $(".home-section2__group2-price-procesador .column1").text(
      `${(procesadorCostPerUnitMonthly / magnitudeForMonthly).toFixed(2)}€`,
    );
    $(".home-section2__group2-price-procesador .column2").text(
      `${(totalProcesadorCost / magnitudeForMonthly).toFixed(2)}€`,
    );

    const totalSsdCost = ssdCostPerUnitMonthly * ssdValue;
    $(".home-section2__group2-price-ssd .column1").text(
      `${(ssdCostPerUnitMonthly / magnitudeForMonthly).toFixed(2)}€`,
    );
    $(".home-section2__group2-price-ssd .column2").text(
      `${(totalSsdCost / magnitudeForMonthly).toFixed(2)}€`,
    );

    const total =
      (totalLicenciaCost + totalRamCost + totalProcesadorCost + totalSsdCost) /
      magnitudeForMonthly;
    $(".home-section2__group2-price-table .total .total-amount").text(
      `${total.toFixed(2)}€`,
    );
    $(
      ".home-section2__group2-price-table .total .total-per-period-month",
    ).show();
    $(
      ".home-section2__group2-price-table .total .total-per-period-hour",
    ).hide();
  } else {
    const licenciaCostPerUnit =
      licenciaValue === "linux" ? linuxHourly : windowsHourly;
    const totalLicenciaCost = licenciaCostPerUnit * vCoreValue;
    const licenciaCostPerUnitWithMagnitude =
      licenciaCostPerUnit / magnitudeForHourly;
    const totalLicenciaCostWithMagnitude =
      totalLicenciaCost / magnitudeForHourly;
    const licenciaCostPerUnitDisplayText =
      licenciaValue === "linux"
        ? licenciaCostPerUnitWithMagnitude
        : licenciaCostPerUnitWithMagnitude.toFixed(5);
    const totalLicenciaCostPerUnitDisplayText =
      licenciaValue === "linux"
        ? totalLicenciaCostWithMagnitude
        : totalLicenciaCostWithMagnitude.toFixed(5);
    $(".home-section2__group2-price-licensia .column1").text(
      `${licenciaCostPerUnitDisplayText}€`,
    );
    $(".home-section2__group2-price-licensia .column2").text(
      `${totalLicenciaCostPerUnitDisplayText}€`,
    );

    const totalRamCost = ramCostPerUnitHourly * ramValue * vCoreValue;
    $(".home-section2__group2-price-ram .column1").text(
      `${(ramCostPerUnitHourly / magnitudeForHourly).toFixed(5)}€`,
    );
    $(".home-section2__group2-price-ram .column2").text(
      `${(totalRamCost / magnitudeForHourly).toFixed(5)}€`,
    );

    const totalProcesadorCost = procesadorCostPerUnitHourly * vCoreValue;
    $(".home-section2__group2-price-procesador .column1").text(
      `${(procesadorCostPerUnitHourly / magnitudeForHourly).toFixed(5)}€`,
    );
    $(".home-section2__group2-price-procesador .column2").text(
      `${(totalProcesadorCost / magnitudeForHourly).toFixed(5)}€`,
    );

    const totalSsdCost = ssdCostPerUnitHourly * ssdValue;
    $(".home-section2__group2-price-ssd .column1").text(
      `${(ssdCostPerUnitHourly / magnitudeForHourly).toFixed(5)}€`,
    );
    $(".home-section2__group2-price-ssd .column2").text(
      `${(totalSsdCost / magnitudeForHourly).toFixed(5)}€`,
    );

    const total =
      (totalLicenciaCost + totalRamCost + totalProcesadorCost + totalSsdCost) /
      magnitudeForHourly;
    $(".home-section2__group2-price-table .total .total-amount").text(
      `${total.toFixed(5)}€`,
    );
    $(
      ".home-section2__group2-price-table .total .total-per-period-month",
    ).hide();
    $(
      ".home-section2__group2-price-table .total .total-per-period-hour",
    ).show();
  }
}

function setMonthToggle() {
  $(".home-section2__toggle input").change(function (e) {
    isPerMonth = !e.target.checked;
    updateCalculation();
  });
}

function setLicenciaRadio() {
  licenciaValue = $(".home-section2__group-license input").val();
  $(".home-section2__group-license input").change(function (e) {
    licenciaValue = e.target.value;
    updateCalculation();
  });
}

function setRamRadio() {
  ramValue = parseInt($(".home-section2__group-ram-group input").val());
  $(".home-section2__group-ram-group input").change(function (e) {
    ramValue = parseInt(e.target.value);
    if (ramValue === 4 && vCoreValue === 0.5) {
      $(".home-section2__vcore-range input").val(1);
      setTimeout(function () {
        $(".home-section2__vcore-range input").trigger("input");
      }, 0);
    } else {
      updateCalculation();
    }
  });
}

function setVCoreRange() {
  const index = $(".home-section2__vcore-range input").val();
  vCoreValue = vCoreRangeValues[index];
  $(".home-section2__vcore-range input").on("input", function (e) {
    const index = e.target.value;
    vCoreValue = vCoreRangeValues[index];
    if (ramValue === 4 && vCoreValue === 0.5) {
      $(".home-section2__group-ram-group-2 input").click();
    } else {
      updateCalculation();
    }
  });
  $(".home-section2__vcore-range .range__minus-button").click(function (e) {
    const index = parseInt($(".home-section2__vcore-range input").val());
    if (index !== 0) {
      $(".home-section2__vcore-range input").val(index - 1);
      $(".home-section2__vcore-range input").trigger("input");
    }
  });
  $(".home-section2__vcore-range .range__plus-button").click(function (e) {
    const index = parseInt($(".home-section2__vcore-range input").val());
    if (index !== vCoreRangeValues.length - 1) {
      $(".home-section2__vcore-range input").val(index + 1);
      $(".home-section2__vcore-range input").trigger("input");
    }
  });
}

function setSsdRange() {
  // Set Default
  ssdValue = $(".home-section2__ssd-range input").val() * 5;

  $(".home-section2__ssd-range input").on("input", function (e) {
    ssdValue = e.target.value * 5;
    updateCalculation();
  });

  $(".home-section2__ssd-range .range__minus-button").click(function (e) {
    const index = parseInt($(".home-section2__ssd-range input").val());
    if (index !== 0) {
      $(".home-section2__ssd-range input").val(index - 1);
      $(".home-section2__ssd-range input").trigger("input");
    }
  });

  $(".home-section2__ssd-range .range__plus-button").click(function (e) {
    const index = parseInt($(".home-section2__ssd-range input").val());
    if (index !== 380) {
      $(".home-section2__ssd-range input").val(index + 1);
      $(".home-section2__ssd-range input").trigger("input");
    }
  });
}

$(document).ready(function () {
  setMonthToggle();
  setLicenciaRadio();
  setRamRadio();
  setVCoreRange();
  setSsdRange();

  // Initialize the page data
  updateCalculation();
});
