import './home.scss';
import './js/vertical-tabs-home';
import './js/rangeSlider';
import './js/testimonial-carousel';
import './js/precioPorMesHora';
import './js/form-circle-tabs';
import './partials/faqs/faq';

import '../../js/form-init';
import '../../js/dropdown-menu';
import '../../js/floating-header';
import '../../js/nav-row';
import '../../js/scroll-animation';
import '../../js/lazy-load';
import '../../js/lazy-load-recaptcha';
import '../../js/lottie';

import '../../js/footer-current-year';

import $ from 'jquery';

import { scrollTo } from '../../js/scroll-to-contact';


$(function () {
  $('.home-section1__configuration1').click(function () {
    update(2, 0, 0);
  });

  $('.home-section1__configuration2').click(function () {
    update(2, 1, 6);
  });

  $('.home-section1__configuration3').click(function () {
    update(2, 2, 12);
  });

  $('.home-section1__configuration4').click(function () {
    update(4, 15, 20);
  });

  $('.home-section1__configuration').click(function () {
    scrollTo($('.home-section2__title').offset().top)
  });

  function update(ram, vCore, ssd) {
    $('.home-section2__group-license-linux input').click();
    // $('.home-section2__group-license-windows').removeAttr('checked');

    if (ram === 4) {
      $('.home-section2__group-ram-group-4 input').click();
    }
    else {
      $('.home-section2__group-ram-group-2 input').click();
    }

    $('.home-section2__vcore-range input').val(vCore);
    $('.home-section2__vcore-range input').trigger('input');

    $('.home-section2__ssd-range input').val(ssd);
    $('.home-section2__ssd-range input').trigger('input');

    scrollTo($('.home-section2__title').offset().top)
  }
});
