import $ from "jquery";

$(function () {
  // Get element with class 'footer__copyright-year'
  const $footerCurrentYear = $(".footer__copyright-year");

  // Get current year
  const currentYear = new Date().getFullYear();

  // Set current year to element
  $footerCurrentYear.text(currentYear);
});
